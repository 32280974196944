import { useContext, useEffect } from "react"
import { AuthContext } from "../../components/auth-context"
import SettingsColorsCard from "../../components/settings-card-colors"
import SettingsMarginsCard from "../../components/settings-card-margins"
import SettingsStartingBalanceCard from "../../components/settings-card-starting-balance"
import { SettingsCardsWrapper } from "./styled"
import SettingsPenalizationsCard from "../../components/settings-penalizations"

export const Settings = () => {
  const { getCurrentUser } = useContext(AuthContext)

  useEffect(() => {
    getCurrentUser().then(user => {
      if (!user.isLogged) return
    })
  }, [getCurrentUser])

  return (
    <SettingsCardsWrapper>
      <SettingsStartingBalanceCard />
      <SettingsMarginsCard />
      <SettingsColorsCard />
      <SettingsPenalizationsCard />
    </SettingsCardsWrapper>
  )
}
