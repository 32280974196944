import { Alert, Form, InputNumber, Spin } from "antd"
import axios from "axios"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { getBrokerPair, getPairStrip } from "../../helpers"
import { colors } from "../../styles"
import { BrokerPair, PenalizationDataType } from "../../types"
import { AuthContext } from "../auth-context"
import CustomTooltip from "../tooltip"
import { reshapeAllData, reshapeDefaultValue } from "./helpers"
import {
  FormItemStyled,
  FormScrollable,
  FormStyled,
  SettingsButtonStyled,
  SettingsGrid,
  SettingsGridCell,
  SettingsGridHeaderCell,
  SettingsGridRow,
  TitleSettingsCard,
  TitleSettingsWrapper,
  WrapperSettingsCard,
} from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/penalizations"

const SettingsPenalizationsCard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { getCurrentUser } = useContext(AuthContext)
  const [allData, setAllData] = useState<PenalizationDataType>()
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [defaultValues, setDefaultValues] = useState<{}>({})
  const [responseError, setResponseError] = useState<boolean>()
  const [responseSuccess, setResponseSuccess] = useState<boolean>()

  const getPenalizationsData = useCallback(
    () =>
      getCurrentUser()
        .then(user => {
          if (!user.isLogged) return
          axios
            .get<PenalizationDataType>(url, {
              headers: {
                Authorization: user.tokens.token,
              },
            })
            .then(({ data }) => {
              setAllData(data)
              const newDefaultValues = reshapeDefaultValue(
                data.penalizationsData,
              )
              setDefaultValues(newDefaultValues)
              setIsLoading(false)
            })
        })
        .catch(console.error),
    [getCurrentUser],
  )

  useEffect(() => {
    setIsLoading(true)
    getPenalizationsData()
  }, [getPenalizationsData])

  const onFinish = async (values: any) => {
    setResponseSuccess(false)
    setResponseError(false)
    const dataToSend = {
      penalizationsData: values as Record<BrokerPair, number>,
    }
    const user = await getCurrentUser()
    if (!user.isLogged) return
    const res = await axios.post<{
      lastUpdate: number
      data: Partial<Record<BrokerPair, number>>
    }>(url, dataToSend, {
      headers: {
        Authorization: user.tokens.token,
      },
    })
    if (
      res.status === 200 &&
      res.data &&
      allData &&
      allData.penalizationsData
    ) {
      const newAllData = reshapeAllData(allData, res.data)
      setAllData(newAllData)
      const newDefaultValues = reshapeDefaultValue(newAllData.penalizationsData)
      setDefaultValues(newDefaultValues)
      setResponseSuccess(true)
      setResponseError(false)
    } else {
      setResponseSuccess(false)
      setResponseError(true)
    }
  }

  return (
    <WrapperSettingsCard isLoading={isLoading}>
      {isLoading ? (
        <Spin />
      ) : (
        <div className="immovable">
          <TitleSettingsWrapper>
            <TitleSettingsCard>
              Penalizations Configuration (in DPM)
            </TitleSettingsCard>
          </TitleSettingsWrapper>
          <FormScrollable>
            <FormStyled
              name="penalizationForm"
              layout={"horizontal"}
              onFinish={onFinish}
              form={form}
              ref={formRef}
              requiredMark={false}
              initialValues={defaultValues}
            >
              <SettingsGrid numCols={allData?.pairs.length ?? 0}>
                <SettingsGridRow>
                  <SettingsGridCell key={`undefined-pair`} />
                  {allData &&
                    allData.pairs.map((pair, index) => {
                      return (
                        <SettingsGridHeaderCell key={`${index}-${pair}-pair`}>
                          {getPairStrip(pair)}
                        </SettingsGridHeaderCell>
                      )
                    })}
                </SettingsGridRow>
                {allData &&
                  allData.brokers.map((broker, index) => {
                    return (
                      <SettingsGridRow key={`brokerRow-${broker}-${index}`}>
                        <SettingsGridCell key={`${index}-${broker}-pair`}>
                          {broker}
                        </SettingsGridCell>
                        {allData.pairs.map(pair => {
                          const key = getBrokerPair(broker, pair) as BrokerPair
                          const valuesAreDifferent =
                            Math.abs(
                              allData.penalizationsData[key].aggregated -
                                allData.penalizationsData[key].expected,
                            ) > 1e-5
                          const tooMuchDelay =
                            new Date().getTime() -
                              new Date(allData.lastUpdate).getTime() >
                            1_000 * 60 * 12
                          return (
                            <CustomTooltip
                              key={`${key}-tooltip`}
                              show={true}
                              title={`Aggregator value is ${allData.penalizationsData[key].aggregated}`}
                              color={colors.iUltraDarkGrey}
                              overlayInnerStyle={{
                                borderColor: colors.iLightGrey,
                                borderWidth: 3,
                                borderStyle: "solid",
                                borderRadius: 10,
                              }}
                            >
                              <SettingsGridCell key={`${broker}-${pair}-item`}>
                                <FormItemStyled
                                  $red={valuesAreDifferent && tooMuchDelay}
                                  label={null}
                                  name={key}
                                  colon={false}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input a correct penalization!",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    controls={false}
                                    formatter={value => {
                                      if (value === undefined) return "0"
                                      return new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 0,
                                      }).format(Number(value))
                                    }}
                                  />
                                </FormItemStyled>
                              </SettingsGridCell>
                            </CustomTooltip>
                          )
                        })}
                      </SettingsGridRow>
                    )
                  })}
              </SettingsGrid>
              <FormItemStyled {...{ wrapperCol: { span: 24, offset: 0 } }}>
                <SettingsButtonStyled type="primary" htmlType="submit">
                  Submit
                </SettingsButtonStyled>
              </FormItemStyled>
            </FormStyled>
          </FormScrollable>
          {responseSuccess ? (
            <Alert message="Data successfully updated" type="success" />
          ) : null}
          {responseError ? (
            <Alert message="Unfortunately an error occurred" type="error" />
          ) : null}
        </div>
      )}
    </WrapperSettingsCard>
  )
}

export default SettingsPenalizationsCard
